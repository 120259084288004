import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { get } from "helpers/api_helper";

import authHeader from "helpers/jwt-token-access/auth-token-header";

const RoomGeneral = ({ values,  onInputChange, setActiveTab }) => {
  // State for validation errors

  const [errors, setErrors] = useState({});
  // const [allUser, setAllUser] = useState()
  // const [residence, setResidence] = useState()
  const [roomtypes, setRoomtypes] = useState();

  // const [selectedMulti, setselectedMulti] = useState(null);

  const navigate = useNavigate();
  // Validation function
  const validate = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      // "owner_id",
      "number",
      // "residence_id",
      "room_type",
      "publish",

      "price",
  
    ];

    requiredFields.forEach((field) => {
      const value = field
        .split(".")
        .reduce((acc, part) => acc && acc[part], values);
      if (!value || (Array.isArray(value) && value.length === 0)) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle Next button click
  const handleNext = () => {
    if (validate()) {
      setActiveTab("2");
    }
  };

  const getAllRoomType = async () => {
    const token = authHeader();
    const config = {
      headers: {
        Authorization: token,
      },
    };

    try {
      const res = await get(`/roomtypes`, config);

      setRoomtypes(res?.data);
    } catch (error) {
      console.log("an error occured", error);
    }
  };


  useEffect(() => {

    // getAllUser()
    getAllRoomType();
  
  }, []);

  return (
    <div>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="name">
              Name <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="name"
              type="text"
              id="name"
              placeholder="Enter Name"
              onChange={onInputChange}
              value={values?.name || ""}
              invalid={!!errors.name}
            />
            {errors.name && (
              <FormFeedback style={{ color: "red" }}>
                {errors.name}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="room_type">
              Room Type <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="room_type"
              type="select"
              id="room_type"
              placeholder="Enter Apartment Type"
              onChange={onInputChange}
              value={values?.room_type || ""}
              invalid={!!errors.room_type}
            >
              <option value="">Select</option>

              {roomtypes?.map((item) => {
                return (
                  <>
                    <option key={item?.id} value={item?.roomtype}>
                      {item?.roomtype}
                    </option>
                  </>
                );
              })}
            </Input>
            {errors.room_type && (
              <FormFeedback style={{ color: "red" }}>
                {errors.room_type}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="number">
              Room No. <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="number"
              type="text"
              id="number"
              placeholder="Enter room  number "
              onChange={onInputChange}
              value={values?.number || ""}
              invalid={!!errors.number}
            />
            {errors.number && (
              <FormFeedback style={{ color: "red" }}>
                {errors.number}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="price">
              Price <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="price"
              type="text"
              id="price"
              placeholder="Enter price"
              onChange={onInputChange}
              value={values?.price || ""}
              invalid={!!errors.price}
            />
            {errors.price && (
              <FormFeedback style={{ color: "red" }}>
                {errors.price}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="deposit">
            Deposit  <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="deposit"
              type="text"
              id="deposit"
              placeholder="Enter Deposit"
              onChange={onInputChange}
              value={values?.deposit || ""}
              invalid={!!errors.deposit}
             

            />
            {errors.deposit && (
              <FormFeedback style={{ color: "red" }}>
                {errors.deposit}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="publish">
              Published <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="select"
              name="publish"
              id="publish"
              onChange={onInputChange}
              value={values?.publish || ""}
              invalid={!!errors.publish}
            >
              <option value="">Select publish type</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </Input>
            {errors.publish && (
              <FormFeedback style={{ color: "red" }}>
                {errors.publish}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        {/* <Col md={4}>
          <FormGroup>
            <Label for="status">
              Availability <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="select"
              name="status"
              id="status"
              onChange={onInputChange}
              value={values?.status || ""}
              invalid={!!errors.status}
            >
              <option value="">Select availability</option>
              <option value="Available">Available</option>
              <option value="Unavailable">Unavailable</option>
            
            </Input>
            {errors.status && (
              <FormFeedback style={{ color: "red" }}>
                {errors.status}
              </FormFeedback>
            )}
          </FormGroup>
        </Col> */}
      </Row>
      <Row>
        {/* <Col md={4}>
          <FormGroup>
            <Label for="slot-selection">Select Slots</Label>
            <Input
              type="select"
              id="slot"
              name="slot"
              onChange={onInputChange}
              value={values?.slot || ""}
              invalid={!!errors.slot}
            >
              
              {!values?.slot && <option value="">Select Slot</option>}

              {allSlot?.map((item) => {
                return (
                  <>
                    <option key={item?.id} value={item?.id}>
                      {item?.slot}
                    </option>
                  </>
                );
              })}
            </Input>
          </FormGroup>
          {errors.slot && (
            <FormFeedback style={{ color: "red" }}>{errors.slot}</FormFeedback>
          )}
        </Col> */}
      </Row>
      {/* <Row>
        
      </Row> */}

      <Row>
        <Col md={6}>
          <Button
            color="secondary"
            onClick={() =>
              navigate(`/residence/edit/${values.residence_id}`, {
                state: { data: "rooms" },
              })
            }
          >
            Back
          </Button>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button color="primary" onClick={handleNext}>
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
};

// Define PropTypes
RoomGeneral.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  Fdata: PropTypes.object,

  setActiveTab: PropTypes.func.isRequired,
};

export default RoomGeneral;

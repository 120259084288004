import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Default is localStorage for web

import rootReducer from "./reducers"; // Your combined reducers
import rootSaga from "./sagas"; // Your sagas

// Create saga middleware
const sagaMiddleware = createSagaMiddleware();

// Redux DevTools Enhancer
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Persist Config
const persistConfig = {
  key: "root", // Key for localStorage (or AsyncStorage for React Native)
  storage, // Define the storage engine

};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

// Run the sagas
sagaMiddleware.run(rootSaga);

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };


import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Label,
  Input,
  Button
} from "reactstrap";
import { post, get } from "helpers/api_helper";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import { connect } from "react-redux";
import withRouter from 'components/Common/withRouter';
import Breadcrumb from "../../components/Common/Breadcrumb";
import avatar from "../../assets/images/users/user-4.jpg";
import { editProfile, resetProfileFlag } from "../../store/actions";
import Swal from 'sweetalert2';
import Loader from 'components/loader/Loader';

const UserProfile = (props) => {
  const [user, setUser] = useState({});
  const [initialUser, setInitialUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [apikey, setApiKey] = useState("");
  const [iscopied,setIscopied] = useState(false);


  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setApiKey(obj.token);
      fetchUserById(obj.user.id);
      setTimeout(() => {
        props.resetProfileFlag();
      }, 3000);
    }
  }, [props.success]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const hasUserChanged = () => {
    return JSON.stringify(user) !== JSON.stringify(initialUser);
  };

  const submitHandler = () => {
    if (!hasUserChanged()) {
      Swal.fire({
        position: "center",
        icon: "info",
        title: "No changes detected",
        showConfirmButton: true,
      });
      return; // Stop if there are no changes
    }

    const token = authHeader();
    const config = {
      headers: {
        Authorization: token,
      },
    };

    post(`/users/${user.id}`, user, config)
      .then(res => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: res?.message || "Profile updated successfully",
          showConfirmButton: false,
          timer: 3000,
        });
      })
      .catch(error => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Error updating user",
          text: error.response?.data,
          showConfirmButton: true,
        });
      });
  };

  const fetchUserById = async (id) => {
    if (!id) return; // Return early if ID is not available
    try {
      const token = authHeader();
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const res = await get(`/users/${id}`, config);
      setLoading(false);
      setUser(res); // Assuming the API returns user data in res.data
      setInitialUser(res); // Set the initial user data
    } catch (error) {
      console.log("Error fetching user:", error);
    }
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(apikey).then(() => {
      // alert("API Key copied to clipboard!");
      setIscopied(true)
    }).catch(err => {
      console.error("Failed to copy: ", err);
    });
    setTimeout(()=>{
           setIscopied(false)
    },1000)
  };


  document.title = "Profile | SHS Admin";

  return (
    <React.Fragment>
      {loading ? <Loader /> : (
        <div className="page-content">
          <Container fluid>
            <Breadcrumb maintitle="Home" title="Profile" breadcrumbItem="Profile" />
            <Row>
              <Col lg="12">
                {props.error && <Alert color="danger">{props.error}</Alert>}
                {props.success && <Alert color="success">{props.success}</Alert>}
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="mx-3">
                        <img
                          src={avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          <h5>{`${user.first_name} ${user.last_name}`}</h5>
                          <p className="mb-1">{user.user_email}</p>
                          <p className="mb-0">Id no: #{user.id}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <h4 className="card-title mb-4">Profile Details</h4>
            <Card>
              <CardBody>
                <Row className="mb-4">
                  <Col md={4}>
                    <Label for="first_name">
                      First Name <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="first_name"
                      type="text"
                      id="first_name"
                      placeholder="Enter first name"
                      value={user.first_name || ""}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md={4}>
                    <Label for="last_name">
                      Last Name <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="last_name"
                      type="text"
                      id="last_name"
                      placeholder="Enter last name"
                      value={user.last_name || ""}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md={4}>
                    <Label for="user_email">
                      Email <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="user_email"
                      type="text"
                      id="user_email"
                      placeholder="Enter email"
                      disabled
                      value={user.user_email || ""}
                      style={{ cursor: "not-allowed" }}
                    />
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col md={4}>
                    <Label for="user_name">
                      User Name <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="user_login"
                      type="text"
                      id="user_name"
                      placeholder="Enter user name"
                      value={user.user_login || ""}
                      onChange={handleInputChange}
                    />
                  </Col>
                  {/* Added the password field */}
                  <Col md={4}>
                    <Label for="user_pass">
                      Password <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="user_pass"
                      type="text"
                      id="user_pass"
                      placeholder="Enter password"
                      value={user.user_pass || ""}
                      onChange={handleInputChange}
                      disabled
                      style={{ cursor: "not-allowed" }}
                    />
                  </Col>
                  <Col md={4}>
                    <Label for="domain">
                      Domain <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="domain"
                      type="text"
                      id="domain"
                      placeholder="Enter domain"
                      value={user.domain || ""}
                      onChange={handleInputChange}
                    />
                  </Col>
               
              
                </Row>

                <Row className="mb-4">
                <Col md={4}>
                    <Label for="apikey">
                      API Key <span style={{ color: "red" }}>*</span>
                    </Label>
                    <div className="input-group">
      <span className="input-group-text" onClick={copyToClipboard}>
        <i className="ti-clipboard"></i>
      </span>
      <Input
        name="apikey"
        type="text"
        id="apikey"
        placeholder="API key"
        disabled
        value={apikey || ""}
      />
  
    </div>
{ iscopied&&   <p style={{color:"green"}}>Copied  to  clipboard !</p>}
                  </Col>
                <Col md={4}>
                    <Label for="woo_url">
                    WooCommerce Store URL <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="woo_url"
                      type="text"
                      id="woo_url"
                      placeholder="Enter woo_url"
                      value={user.woo_url || ""}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md={4}>
                    <Label for="woo_key">
                    WooCommerce Consumer Key <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="woo_key"
                      type="text"
                      id="woo_key"
                      placeholder="Enter woo_key"
                      value={user.woo_key || ""}
                      onChange={handleInputChange}
                    />
                  </Col>
           
                </Row>
                <Row className="mb-4">
                <Col md={4}>
                    <Label for="woo_secret">
                    WooCommerce Consumer Secret <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="woo_secret"
                      type="text"
                      id="woo_secret"
                      placeholder="Enter woo_secret"
                      value={user.woo_secret || ""}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}></Col>
                  <Col md={6} className="d-flex justify-content-end">
                    <Button type="button" color="primary" onClick={submitHandler}>
                      Update Profile  
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  resetProfileFlag: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag })(UserProfile)
);
